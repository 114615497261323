import React from "react";
//img
import logo1 from "../../../img/dashboard/fooder_dashboard.svg"
import icon1 from "../../../img/vector/logo_fooder_short.svg"
import logocorp2 from "../../../img/dashboard/fooder_dashboard_2.svg"
import iconcorp2 from "../../../img/dashboard/logo_fooder_short_2.svg"
import logocorp3 from "../../../img/dashboard/fooder_dashboard_3.svg"
import iconcorp3 from "../../../img/dashboard/logo_fooder_short_3.svg"
import { useDispatch, useSelector } from "react-redux"
import { resetAllPost } from "../../../redux/reducer/post";

const Lateral = () => {

  const { cuota, credits, filteredZero} = useSelector(state => state.log)
  const dispatch = useDispatch()



  const listLinks = [
    {title: 'Novedades', class:'novedades', fx: false, active: true, disabled: false},
    {title: 'Configuración', class:'configuracion', fx: false, active: false, disabled: credits <1 ? true: false},
    {title: 'Mi plantilla', class:'plantilla', fx: false, active: false, disabled: credits <1 ? true: false},
    {title: 'Productos', class:'productos', fx: false, active: false, disabled: credits <1 ? true: false},
    {title: 'Mi cocina', class:'cocina', fx: false, active: false, disabled: credits <1 ? true: false, notification: true,},
    {title: 'Reportes', class:'reportes', fx: false, active: false, disabled: credits <1 ? true: false},
    {title: 'Crear Post', class:'post', fx: false, active: false, disabled: credits <1 ? true: false},
    {title: 'Crear Estado', class:'state', fx: false, active: false, disabled: credits <1 ? true: false},
    {title: 'Mi QR', class:'qr', fx: false, active: false, disabled: credits <1 ? true: false},
    {title: 'Libreta clientes', class:'libreta', active: false, fx: false, disabled: credits <1 ? true: false},
  ]


  const handleTop = (fx)=> {
    document.querySelector('aside').scrollTo(0,0)
    if(fx){
      dispatch(resetAllPost())
    } 
  }

  return (
    <>
      <div className='logoDash'>
        {
          cuota === 1 && (
            <>
              <img src={logo1} alt="Logo Fooder" className="img-fluid logo1" />
              <img src={icon1} alt="Logo Fooder" className="img-fluid logo2" />
            </>
          )
        }
        {
          cuota === 2 && (
            <>
              <img src={logocorp2} alt="Logo Fooder" className="img-fluid logo1" />
              <img src={iconcorp2} alt="Logo Fooder" className="img-fluid logo2" />
            </>
          )
        }
        {
          cuota === 3 && (
            <>
              <img src={logocorp3} alt="Logo Fooder" className="img-fluid logo1" />
              <img src={iconcorp3} alt="Logo Fooder" className="img-fluid logo2" />
            </>
          )
        }
      </div>
      <ul className="nav nav-tabs tabLateral" role="tablist">
        {
          listLinks.map((el, index) => {
            if(!el.disabled) {
              return (
                <li className={`nav-item ${el.disabled ? 'disabled': ''}`} role="presentation" key={index}>
                  <button
                    className={`nav-link ${el.active ? 'active': ''} ${el.class}`}
                    id={`${el.class}-tab`}
                    data-bs-toggle={el.disabled ? '': 'tab'}
                    data-bs-target={el.disabled ? null : `#${el.class}-tab-pane`}
                    type="button"
                    role="tab"
                    aria-controls={`#${el.class}-tab-pane`}
                    aria-selected="true"
                    onClick={()=> handleTop(el.fx)}
                  >
                    <span>{el.title}</span>
                    {(el.notification && filteredZero.length > 0) && <div className="point">{filteredZero.length}</div>}
                    
                  </button>
                </li>
              )
            }
          })
        }
        <li>
        <a
          className="btnTikTok"
          href="https://www.tiktok.com/@fooderapp.co"
          target="_blank"
          rel="noreferrer"
        >TikTok</a>
        </li>
        
      </ul>
      
    </>
  );
};

export default Lateral;
