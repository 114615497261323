import { db } from "../../../../../firebase/credentials"
import { doc, updateDoc } from "firebase/firestore"



const updateStep1 = async(userID, obj)=>{

  const ref = doc(db,'admin', userID)

  try {
    await updateDoc(ref, obj)
    return true
  } catch (error) {
    return false
  }

}

export default updateStep1