import React,{useEffect, useState} from 'react'
import dismissStep from '../change-password/fx/dismissStep'
import { useSelector } from 'react-redux'

const WelcomeVideo = () => {

  const {userID} = useSelector(s => s.log)
  const [isLoading, setIsLoading] = useState(true)

  const handleStep = async ()=> await dismissStep(userID,2)

  useEffect(() => {
    // Agregar el script de TikTok dinámicamente al montar el componente
    const script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Eliminar el script al desmontar el componente (opcional)
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(()=>{

    const intervalActive = ()=>{
      setTimeout(()=>{
        setIsLoading(false)
      }, 3500)
    }

    return intervalActive()

  },[])




  return (
    <section className="welcomevideo">
      <div className="content">
        <div className="row justify-content-center">
          <div className="col-12 mb-4">
            <div className="iframeVideo">
              <blockquote
                className="tiktok-embed"
                cite="https://www.tiktok.com/@fooderapp.co/video/7437635423960091960"
                data-video-id="7437635423960091960"
                data-muted="false"
              >
                <section>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="@fooderapp.co"
                    href="https://www.tiktok.com/@fooderapp.co?refer=embed"
                  >
                    @fooderapp.co
                  </a>{" "}
                  en fooderapp.co puedes crear tu propio menú digital para recibir pedidos
                  directamente a tu WhatsApp{" "}
                  <a
                    title="domicilios"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/tag/domicilios?refer=embed"
                  >
                    #domicilios
                  </a>{" "}
                  <a
                    title="restaurante"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/tag/restaurante?refer=embed"
                  >
                    #restaurante
                  </a>{" "}
                  <a
                    title="menu"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/tag/menu?refer=embed"
                  >
                    #menu
                  </a>{" "}
                  <a
                    title="tecnologia"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/tag/tecnologia?refer=embed"
                  >
                    #tecnologia
                  </a>{" "}
                  <a
                    title="bienvenida"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/tag/bienvenida?refer=embed"
                  >
                    #bienvenida
                  </a>{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="♬ original sound  - Fooder"
                    href="https://www.tiktok.com/music/original-sound-Fooder-7437635434843622199?refer=embed"
                  >
                    ♬ original sound - Fooder
                  </a>
                </section>
              </blockquote>
              {isLoading && (
                <div className="isLoadingVideo"><div /></div>
              )}
            </div>
          </div>
          <div className="col-9">
            <button className={`btnPurple w-100 ${isLoading ? 'disabled': ''}`} onClick={handleStep}>Continuar</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WelcomeVideo
